import StatBar from "./statBar";
import * as Phaser from "phaser";
import { spitBarLength } from "../uiConstants";
import { Colour } from "./colours";

const MAX_SPIT = 100;
const SPIT_REGEN_RATE = 10;
const SPIT_REGEN_TIMEOUT = 5000;

class SpitBar extends StatBar {
  private _timeWhenSpitRegenerationDue: number;
  private _timeWhenSpitRegenerationAllowed: number;

  constructor(scene: Phaser.Scene, x: number, y: number, left: boolean) {
    super(scene, MAX_SPIT, x, y, left, spitBarLength, Colour.BLUE);
    this._timeWhenSpitRegenerationDue = 0;
    this._timeWhenSpitRegenerationAllowed = 0;
  }

  protected onDecrease(): void {
    if (this.isEmpty()) {
      this._timeWhenSpitRegenerationAllowed =
        this.scene.time.now + SPIT_REGEN_TIMEOUT;
    }
  }

  update(): void {
    const now: number = this.scene.time.now;
    if (
      !this.isFull() &&
      now > this._timeWhenSpitRegenerationDue &&
      now > this._timeWhenSpitRegenerationAllowed
    ) {
      this.increase(0.2);
      this._timeWhenSpitRegenerationDue = now + SPIT_REGEN_RATE;
    }
  }
}

export default SpitBar;

import * as Phaser from "phaser";
import { Utils } from "./utils";
import { playerConfigs } from "./playerConfig";
import { TextButton } from "./button";

export class StartScene extends Phaser.Scene {
  private _sceneWidth: number;
  private _sceneHeight: number;

  constructor() {
    super("start");
  }

  public preload(): void {
    this.load.image("background", "assets/backgrounds/bg_classic.png");
    this.load.image("smoke", "assets/sprites/smoke.png");
    playerConfigs.forEach((player) => {
      this.load.image(
        `${player.sprite}_single`,
        `assets/sprites/${player.sprite}.png`
      );
    });

    const { width, height } = Utils.getDimensions(this);
    this._sceneWidth = width;
    this._sceneHeight = height;
  }

  public create(): void {
    this.showBackground();
    this.showSmoke();
    this.showTitle();
    this.showPlayerControls();
    this.showFightButton();
  }

  private showBackground(): void {
    this.add.image(0, 0, "background").setScale(0.8, 0.8).setOrigin(0);
  }

  private showTitle(): void {
    const titleStyle = {
      fontFamily: "Butcherman",
      fontSize: "100px",
      align: "center",
      color: "black",
    };
    this.add
      .text(
        this._sceneWidth * 0.5,
        this._sceneHeight * 0.1,
        "ALPACALYPSE",
        titleStyle
      )
      .setOrigin(0.5);
  }

  private showPlayerControls(): void {
    const textStyle = {
      fontFamily: "Special Elite",
      fontSize: "25px",
      color: "black",
    };

    const xPositions = [0.25, 0.75];
    playerConfigs.forEach((player, i) => {
      const xPosition = this._sceneWidth * xPositions[i];
      this.add
        .image(xPosition, this._sceneHeight * 0.35, `${player.sprite}_single`)
        .setOrigin(0.5)
        .setScale(0.6, 0.6)
        .setFlipX(i % 2 != 0);

      const controls = player.controls;
      const playerText = `Player ${i + 1}`;
      const controlText =
        `Left: ${controls.leftKey}\nRight: ${controls.rightKey}\nJump: ${controls.upKey}` +
        `\nSpit: ${controls.spitKey}\nKick: ${controls.kickKey}`;
      this.add
        .text(xPosition, this._sceneHeight * 0.55, playerText, {
          ...textStyle,
          align: "center",
        })
        .setOrigin(0.5);
      this.add
        .text(xPosition, this._sceneHeight * 0.67, controlText, textStyle)
        .setOrigin(0.5)
        .setLineSpacing(5);
    });
  }

  private showFightButton(): void {
    const fightStyle = {
      fontFamily: "Special Elite",
      fontSize: "60px",
      align: "center",
      color: "white",
      backgroundColor: "black",
      hoverColour: "red",
    };
    this.add.existing(
      new TextButton(
        this,
        this._sceneWidth * 0.5,
        this._sceneHeight * 0.4,
        " FIGHT ",
        fightStyle,
        () => this.scene.start("arena")
      )
    );
  }

  private showSmoke() {
    this.add.particles("smoke").createEmitter({
      x: { min: 0, max: this._sceneWidth },
      y: this._sceneHeight * 1.3,
      gravityY: -10,
      lifespan: 11000,
      scale: 0.8,
      angle: { min: 0, max: 360 },
      speedY: { min: -4, max: -8 },
      frequency: 1500,
      alpha: { start: 0.4, end: 0 },
    });
  }
}

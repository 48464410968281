import { Player } from "./player";
import { Spit } from "./spit";

const numberOfSpitsInGroup = 30;

export class SpitGroup extends Phaser.Physics.Arcade.Group {
  private _directionRight: boolean;

  constructor(scene: Phaser.Scene, directionRight: boolean) {
    super(scene.physics.world, scene);

    this._directionRight = directionRight;

    const children: Spit[] = [];
    for (let i = 0; i < numberOfSpitsInGroup; i++) {
      children.push(new Spit(scene, directionRight));
    }
    this.addMultiple(children);
  }

  public registerEnemyPlayer(enemyPlayer: Player): void {
    this.getChildren().forEach((gameObject: Phaser.GameObjects.GameObject) => {
      const spit: Spit = gameObject as Spit;
      spit.registerEnemyPlayer(enemyPlayer);
    });
  }

  public fireSpit(x: number, y: number): void {
    const spit: Spit = this.getFirstDead(false);

    if (spit) {
      spit.fire(x, y);
    } else {
      console.warn(
        `Attempted to fire but no spits in group. Direction: ${
          this._directionRight ? "right" : "left"
        }.`
      );
    }
  }

  public getSpitWidth(): number {
    const spit: Spit = this.getChildren()[0] as Spit;
    return spit.getScaledWidth();
  }
}

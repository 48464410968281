export interface Dimensions {
  width: number;
  height: number;
}

export class Utils {
  public static getDimensions(scene: Phaser.Scene): Dimensions {
    return {
      width: scene.cameras.main.width,
      height: scene.cameras.main.height,
    };
  }

  public static getBody(
    sprite: Phaser.GameObjects.Sprite
  ): Phaser.Physics.Arcade.Body {
    return sprite.body as Phaser.Physics.Arcade.Body;
  }

  public static getScaledDimensions(
    sprite: Phaser.GameObjects.Sprite
  ): { width: number; height: number } {
    return {
      width: sprite.width * sprite.scaleX,
      height: sprite.height * sprite.scaleY,
    };
  }
}

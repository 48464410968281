import { Player } from "./player";
import { Utils } from "./utils";

export class Spit extends Phaser.Physics.Arcade.Sprite {
  private _directionRight: boolean;

  constructor(scene: Phaser.Scene, directionRight: boolean) {
    super(scene, 0, 0, directionRight ? "spit_right" : "spit_left");
    scene.add.existing(this);
    // TODO do we also need scene.physics.add.existing(this)?

    this.setActive(false);
    this.setVisible(false);

    this.setScale(0.4, 0.4);

    this._directionRight = directionRight;
  }

  private destroySpit(): void {
    this.setActive(false);
    this.setVisible(false);
  }

  public registerEnemyPlayer(enemyPlayer: Player): void {
    this.scene.physics.add.overlap(this, enemyPlayer, () => {
      // This check is necessary because this function will be triggered multiple times
      if (this.active) {
        enemyPlayer.receiveSpitDamage();
      }
      this.destroySpit();
    });
  }

  public fire(x: number, y: number): void {
    this.body.reset(x, y);

    this.setActive(true);
    this.setVisible(true);

    this.setVelocityX(this._directionRight ? 1000 : -1000);
  }

  public getScaledWidth(): number {
    return Utils.getScaledDimensions(this).width;
  }

  // PREUPDATE event - overridden
  public preUpdate(time: number, delta: number) {
    super.preUpdate(time, delta);

    const spitHalfWidth = Utils.getScaledDimensions(this).width / 2;
    if (
      this.x < -1 * spitHalfWidth ||
      this.x > Utils.getDimensions(this.scene).width + spitHalfWidth
    ) {
      this.destroySpit();
    }
  }
}

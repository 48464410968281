import * as Phaser from "phaser";
import { Utils } from "./utils";
import { TextButton } from "./button";

export class GameOverScene extends Phaser.Scene {
  private _sceneWidth: number;
  private _sceneHeight: number;

  private _winners: string[];

  constructor() {
    super("gameOver");
  }

  public init(params: GameOverSceneParams): void {
    this._winners = params.getWinners();
  }

  public preload(): void {
    this.load.image("background", "assets/backgrounds/bg_classic.png");

    const { width, height } = Utils.getDimensions(this);
    this._sceneWidth = width;
    this._sceneHeight = height;
  }

  public create(): void {
    this.showBackground();
    this.showGameOver();
    this.showWinner();
    this.showRestartButton();
  }

  private showBackground(): void {
    this.add.image(0, 0, "background").setScale(0.8, 0.8).setOrigin(0);
  }

  private showGameOver(): void {
    const gameOverStyle = {
      fontFamily: "Butcherman",
      fontSize: "100px",
      align: "center",
      color: "black",
    };
    this.add
      .text(
        this._sceneWidth * 0.5,
        this._sceneHeight * 0.1,
        "GAME OVER",
        gameOverStyle
      )
      .setOrigin(0.5);
  }

  private showWinner(): void {
    const generateWinnerText = (winners: string[]) => {
      if (winners.length == 0) {
        return "It's a tie!";
      }

      let winnerNames: string;

      if (winners.length == 1) {
        winnerNames = winners[0];
      } else {
        winnerNames =
          winners.slice(0, winners.length - 1).join(", ") +
          " and " +
          winners[winners.length - 1];
      }

      return winnerNames + " won!";
    };

    const winnerStyle = {
      fontFamily: "Special Elite",
      fontSize: "60px",
      align: "center",
      color: "black",
    };
    this.add
      .text(
        this._sceneWidth * 0.5,
        this._sceneHeight * 0.45,
        generateWinnerText(this._winners),
        winnerStyle
      )
      .setOrigin(0.5);
  }

  private showRestartButton(): void {
    const restartStyle = {
      fontFamily: "Special Elite",
      fontSize: "60px",
      align: "center",
      color: "white",
      backgroundColor: "black",
      hoverColour: "red",
    };
    this.add.existing(
      new TextButton(
        this,
        this._sceneWidth * 0.5,
        this._sceneHeight * 0.75,
        " RESTART ",
        restartStyle,
        () => this.scene.start("arena")
      )
    );
  }
}

export class GameOverSceneParams {
  private _winners: string[];

  constructor(winners: string[]) {
    this._winners = winners;
  }

  public getWinners(): string[] {
    return this._winners;
  }
}

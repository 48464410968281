import * as Phaser from "phaser";

export class TextButton extends Phaser.GameObjects.Text {
  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    text: string,
    style: TextButtonStyle,
    callback: () => void
  ) {
    super(scene, x, y, text, style);

    this.setOrigin(0.5)
      .setInteractive({ useHandCursor: true })
      .on("pointerover", () => {
        this.setColor(style.hoverColour);
      })
      .on("pointerout", () => {
        this.setColor(style.color);
      })
      .on("pointerdown", () => {
        callback();
      });
  }
}

interface TextButtonStyle extends Phaser.Types.GameObjects.Text.TextStyle {
  color: string;
  hoverColour: string;
}

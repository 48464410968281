export interface PlayerControls {
  leftKey: string;
  rightKey: string;
  upKey: string;
  spitKey: string;
  kickKey: string;
}

export enum PlayerSprite {
  ALPACA_PURPLE = "alpaca_purple",
  ALPACA_GREEN = "alpaca_green",
}

export interface PlayerConfig {
  controls: PlayerControls;
  sprite: PlayerSprite;
  name: string;
  initiallyPositionedLeft: boolean;
}

export const playerConfig: Record<string, PlayerConfig> = {
  playerOne: {
    controls: {
      leftKey: "A",
      rightKey: "D",
      upKey: "W",
      spitKey: "F",
      kickKey: "G",
    },
    sprite: PlayerSprite.ALPACA_PURPLE,
    name: "Player 1",
    initiallyPositionedLeft: true,
  },
  playerTwo: {
    controls: {
      leftKey: "LEFT",
      rightKey: "RIGHT",
      upKey: "UP",
      spitKey: "P",
      kickKey: "O",
    },
    sprite: PlayerSprite.ALPACA_GREEN,
    name: "Player 2",
    initiallyPositionedLeft: false,
  },
};

export const playerConfigs = Object.values(playerConfig);

import * as Phaser from "phaser";
import { SpitGroup } from "./spitGroup";
import { Dimensions, Utils } from "./utils";
import { PlayerConfig } from "./playerConfig";
import HealthBar from "./playerHud/healthBar";
import SpitBar from "./playerHud/spitBar";
import { barWidth } from "./uiConstants";

const KNOCKBACK_FORCE = 400;

export class Player extends Phaser.Physics.Arcade.Sprite {
  private _name: string;

  private _animationState?: "left" | "right";
  private _facingRight: boolean;

  private _leftSpitGroup: SpitGroup;
  private _rightSpitGroup: SpitGroup;
  private _timeWhenSpitFiringAllowed: number;

  private _healthBar: HealthBar;
  private _spitBar: SpitBar;

  private _knockedBack: boolean;
  private _kicked: boolean;
  private _timeWhenKickedAnimationEventDue: number;

  private _leftKey: Phaser.Input.Keyboard.Key;
  private _rightKey: Phaser.Input.Keyboard.Key;
  private _upKey: Phaser.Input.Keyboard.Key;
  private _spitKey: Phaser.Input.Keyboard.Key;
  private _kickKey: Phaser.Input.Keyboard.Key;

  private _enemyPlayer?: Player;

  constructor(
    scene: Phaser.Scene,
    // Where the bottom of the sprite will be positioned
    yBottom: number,
    playerConfig: PlayerConfig
  ) {
    // PHASER CREATION
    const sceneWidth: number = Utils.getDimensions(scene).width;
    const initiallyPositionedLeft = playerConfig.initiallyPositionedLeft;

    const playerX: number = initiallyPositionedLeft ? 102 : sceneWidth - 102;

    super(
      scene,
      playerX,
      0,
      playerConfig.sprite,
      initiallyPositionedLeft ? 4 : 0
    );
    this.setScale(0.75, 0.75);
    const playerHeight: number = Utils.getScaledDimensions(this).height;
    this.setY(yBottom - playerHeight / 2);

    const getPlayerFrames = (frameIndices: number[]) =>
      frameIndices.map((frameIndex: number) => ({
        key: playerConfig.sprite,
        duration: 100,
        frame: frameIndex,
      }));
    this.anims.create({
      key: "left",
      frames: getPlayerFrames([1, 2, 3, 0]),
      repeat: -1,
    });
    this.anims.create({
      key: "right",
      frames: getPlayerFrames([5, 6, 7, 4]),
      repeat: -1,
    });

    scene.add.existing(this);
    scene.physics.add.existing(this);

    const playerBody: Phaser.Physics.Arcade.Body = this
      .body as Phaser.Physics.Arcade.Body;
    playerBody.setGravityY(600);
    playerBody.setCollideWorldBounds(true);

    // FIELD INITIALISATION

    // If the player starts on the left side then it starts facing right (and vice versa)
    this._facingRight = initiallyPositionedLeft;

    this._leftSpitGroup = new SpitGroup(scene, false);
    this._rightSpitGroup = new SpitGroup(scene, true);
    this._timeWhenSpitFiringAllowed = 0;

    this._knockedBack = false;
    this._kicked = false;
    this._timeWhenKickedAnimationEventDue = 0;

    this._leftKey = scene.input.keyboard.addKey(playerConfig.controls.leftKey);
    this._rightKey = scene.input.keyboard.addKey(
      playerConfig.controls.rightKey
    );
    this._upKey = scene.input.keyboard.addKey(playerConfig.controls.upKey);
    this._spitKey = scene.input.keyboard.addKey(playerConfig.controls.spitKey);
    this._kickKey = scene.input.keyboard.addKey(playerConfig.controls.kickKey);

    this._name = playerConfig.name;

    this._healthBar = new HealthBar(scene, 86, 45, initiallyPositionedLeft);
    this._spitBar = new SpitBar(
      scene,
      86,
      45 + barWidth,
      initiallyPositionedLeft
    );
  }

  public getName(): string {
    return this._name;
  }

  public registerEnemyPlayer(enemyPlayer: Player): void {
    this._enemyPlayer = enemyPlayer;
    this._leftSpitGroup.registerEnemyPlayer(enemyPlayer);
    this._rightSpitGroup.registerEnemyPlayer(enemyPlayer);
  }

  public receiveSpitDamage(): void {
    this._healthBar.decrease(10);
    this.knockedBack(false);
  }

  public receiveKickDamage(): void {
    this._healthBar.decrease(20);
    this.knockedBack(true);
  }

  public isAlive(): boolean {
    return !this._healthBar.isEmpty();
  }

  public update(): void {
    this.updatePlayerVelocity();
    this.checkForPlayerSpitting();
    this.checkForPlayerKicking();
    this.checkForKickedAnimation();
    this._spitBar.update();
  }

  private updatePlayerVelocity(): void {
    const playerBody: Phaser.Physics.Arcade.Body = Utils.getBody(this);

    if (this._upKey.isDown && playerBody.touching.down) {
      // Jump
      playerBody.setVelocityY(-450);
    }

    if (this._knockedBack) {
      // No movement (except jumping) allowed when knocked back
      return;
    }

    if (this._leftKey.isDown && !this._rightKey.isDown) {
      // Move left
      if (!playerBody.touching.down && playerBody.velocity.x > -152) {
        // In the air
        if (playerBody.velocity.x > -146) {
          playerBody.setVelocityX(playerBody.velocity.x - 8);
        } else if (playerBody.velocity.x > -148) {
          playerBody.setVelocityX(playerBody.velocity.x - 6);
        } else if (playerBody.velocity.x > -150) {
          playerBody.setVelocityX(playerBody.velocity.x - 4);
        } else {
          playerBody.setVelocityX(playerBody.velocity.x - 2);
        }
      } else {
        // On the ground
        playerBody.setVelocityX(-152);
      }
      if (this._animationState !== "left") {
        if (this._animationState === "right") {
          this.anims.stop();
        }
        this.anims.play("left");
      }
      this._animationState = "left";
      this._facingRight = false;
    } else if (this._rightKey.isDown && !this._leftKey.isDown) {
      // Move right
      if (!playerBody.touching.down && playerBody.velocity.x < 152) {
        // In the air
        if (playerBody.velocity.x < 146) {
          playerBody.setVelocityX(playerBody.velocity.x + 8);
        } else if (playerBody.velocity.x < 148) {
          playerBody.setVelocityX(playerBody.velocity.x + 6);
        } else if (playerBody.velocity.x < 150) {
          playerBody.setVelocityX(playerBody.velocity.x + 4);
        } else {
          playerBody.setVelocityX(playerBody.velocity.x + 2);
        }
      } else {
        // On the ground
        playerBody.setVelocityX(152);
      }
      if (this._animationState !== "right") {
        if (this._animationState === "left") {
          this.anims.stop();
        }
        this.anims.play("right");
      }
      this._animationState = "right";
      this._facingRight = true;
    } else {
      // No movement
      playerBody.setVelocityX(0);
      this.anims.stop();
      this.setFrame(this._facingRight ? 4 : 0);
      this._animationState = undefined;
    }
  }

  private getScaledDimensions(): Dimensions {
    return Utils.getScaledDimensions(this);
  }

  private checkForPlayerSpitting(): void {
    const now: number = this.scene.time.now;
    const playerScaledWidth: number = this.getScaledDimensions().width;
    if (
      this._spitKey.isDown &&
      !this._spitBar.isEmpty() &&
      now > this._timeWhenSpitFiringAllowed &&
      !this._knockedBack
    ) {
      if (this._facingRight) {
        this._rightSpitGroup.fireSpit(
          this.x + (playerScaledWidth + this._leftSpitGroup.getSpitWidth()) / 2,
          this.y
        );
      } else {
        this._leftSpitGroup.fireSpit(
          this.x - (playerScaledWidth + this._leftSpitGroup.getSpitWidth()) / 2,
          this.y
        );
      }

      this._spitBar.decrease(25);
      this._timeWhenSpitFiringAllowed = now + 200;
    }
  }

  private checkForPlayerKicking(): void {
    const enemyPlayer: Player = this._enemyPlayer!;
    const playerDimensions = Utils.getScaledDimensions(this);
    const enemyDimensions = Utils.getScaledDimensions(enemyPlayer);
    const horizontalSpaceBetweenOrigins: number =
      (playerDimensions.width + enemyDimensions.width) / 2;
    const verticalSpaceBetweenOrigins: number =
      (playerDimensions.height + enemyDimensions.height) / 2;
    const horizontalKickingSpaceBetweenPlayers: number = 18;
    if (
      Phaser.Input.Keyboard.JustDown(this._kickKey) &&
      !this._knockedBack &&
      this.x >=
        enemyPlayer.x -
          horizontalSpaceBetweenOrigins -
          horizontalKickingSpaceBetweenPlayers &&
      this.x <=
        enemyPlayer.x +
          horizontalSpaceBetweenOrigins +
          horizontalKickingSpaceBetweenPlayers &&
      // Plus 1 and minus 1 are used here so that kicking doesn't work when one player is just standing on top of the other
      this.y >= enemyPlayer.y - verticalSpaceBetweenOrigins + 1 &&
      this.y <= enemyPlayer.y + verticalSpaceBetweenOrigins - 1
    ) {
      enemyPlayer.receiveKickDamage();
    }
  }

  private checkForKickedAnimation(): void {
    const now: number = this.scene.time.now;
    if (this._kicked && now > this._timeWhenKickedAnimationEventDue) {
      this.setVisible(!this.visible);
      this._timeWhenKickedAnimationEventDue = now + (this.visible ? 150 : 50);
    } else if (!this._kicked && !this.visible) {
      this.setVisible(true);
    }
  }

  private knockedBack(kicked: boolean): void {
    this._knockedBack = true;
    if (kicked) {
      this._kicked = true;
      if (this._enemyPlayer!.x < this.x) {
        this.setVelocityX(KNOCKBACK_FORCE);
      } else {
        this.setVelocityX(-KNOCKBACK_FORCE);
      }
    } else {
      if (this.body.touching.left) {
        this.setVelocityX(KNOCKBACK_FORCE);
      } else {
        this.setVelocityX(-KNOCKBACK_FORCE);
      }
    }
    this.anims.pause();
    this.setFrame(this._facingRight ? 4 : 0);
    this.setDamping(true).setDragX(0.4);
    this.scene.time.delayedCall(800, () => {
      this._knockedBack = false;
      this._kicked = false;
      this.setDamping(false).setDragX(0);
      this.anims.resume();
    });
  }
}

import * as Phaser from "phaser";
import { barOffset, barWidth, borderSize, hudDepth } from "../uiConstants";
import { Colour } from "./colours";
import { Utils } from "../utils";

abstract class StatBar extends Phaser.GameObjects.GameObject {
  protected readonly _maxValue: number;
  protected _value: number;

  protected _sprite: Phaser.GameObjects.Shape;

  protected constructor(
    scene: Phaser.Scene,
    maxValue: number,
    x: number,
    y: number,
    left: boolean,
    length: number,
    colour: Colour
  ) {
    super(scene, "statBar");

    this._maxValue = maxValue;
    this._value = maxValue;

    const getLength = (n: number) => (left ? n : -n);
    x = left ? x : Utils.getDimensions(scene).width - x;

    scene.add
      .polygon(
        x,
        y,
        [
          [0, 0],
          [getLength(length), 0],
          [getLength(length - barOffset), barWidth],
          [0, barWidth],
        ],
        Colour.WHITE
      )
      .setStrokeStyle(borderSize, Colour.BLACK)
      .setOrigin(0, 0.5)
      .setDepth(hudDepth);

    this._sprite = scene.add
      .polygon(
        x + getLength(borderSize / 2),
        y,
        [
          [0, 0],
          [getLength(length - borderSize), 0],
          [getLength(length - barOffset - borderSize), barWidth - borderSize],
          [0, barWidth - borderSize],
        ],
        colour
      )
      .setOrigin(0, 0.5)
      .setDepth(hudDepth);
  }

  decrease(value: number): void {
    this._value = Math.max(0, this._value - value);
    this._sprite.setScale(this._value / this._maxValue, 1);
    this.onDecrease();
  }

  increase(value: number): void {
    this._value = Math.min(this._maxValue, this._value + value);
    this._sprite.setScale(this._value / this._maxValue, 1);
  }

  isFull(): boolean {
    return this._value === this._maxValue;
  }

  isEmpty(): boolean {
    return this._value === 0;
  }

  protected abstract onDecrease(): void;
}

export default StatBar;

import StatBar from "./statBar";
import * as Phaser from "phaser";
import { healthBarLength } from "../uiConstants";
import { Colour } from "./colours";

const MAX_HEALTH = 300;

class HealthBar extends StatBar {
  constructor(scene: Phaser.Scene, x: number, y: number, left: boolean) {
    super(scene, MAX_HEALTH, x, y, left, healthBarLength, Colour.GREEN);
  }

  protected onDecrease(): void {
    this._sprite.setFillStyle(this.getHealthColour());
  }

  private getHealthColour(): Colour {
    if (this._value <= this._maxValue * 0.15) {
      return Colour.RED;
    } else if (this._value <= this._maxValue * 0.45) {
      return Colour.ORANGE;
    } else {
      return Colour.GREEN;
    }
  }
}

export default HealthBar;

import * as Phaser from "phaser";
import { Player } from "./player";
import { Utils } from "./utils";
import { playerConfig, playerConfigs, PlayerSprite } from "./playerConfig";
import { GameOverSceneParams } from "./gameOverScene";
import { Colour } from "./playerHud/colours";
import { borderSize, iconSize } from "./uiConstants";

const backgroundDepth: number = -100;

export class ArenaScene extends Phaser.Scene {
  private _platforms?: Phaser.Physics.Arcade.Group;
  private _ground?: Phaser.GameObjects.Sprite;
  private _topLeftPlatform?: Phaser.GameObjects.Sprite;
  private _movingPlatform?: Phaser.GameObjects.Sprite;
  private _movingPlatformVelocity: number;

  private _players: Player[];

  constructor() {
    super("arena");

    this._platforms = undefined;
    this._ground = undefined;
    this._topLeftPlatform = undefined;
    this._movingPlatform = undefined;
    this._movingPlatformVelocity = 0;

    this._players = [];
  }

  private createPlayerHUD(): void {
    playerConfigs.forEach((player) => {
      this.createPlayerIcon(
        20,
        10,
        player.sprite,
        player.initiallyPositionedLeft
      );
      this.createPlayerLabel(
        90,
        10,
        player.name,
        player.initiallyPositionedLeft
      );
    });
  }

  private createPlayerIcon(
    x: number,
    y: number,
    sprite: PlayerSprite,
    left: boolean
  ): void {
    x = left ? x : Utils.getDimensions(this).width - x - iconSize;
    const [spriteAdjustmentX, spriteAdjustmentY] = [10, 55];
    const borderBoxSize = iconSize + 2 * borderSize;
    this.add
      .rectangle(
        x - borderSize,
        y - borderSize,
        borderBoxSize,
        borderBoxSize,
        Colour.BLACK
      )
      .setOrigin(0);
    const icon = this.add.graphics({ fillStyle: { color: Colour.WHITE } });
    icon.fillRect(x, y, iconSize, iconSize);

    const spriteX = left
      ? x + spriteAdjustmentX
      : x + (iconSize - spriteAdjustmentX);
    const player = this.add
      .sprite(spriteX, y + spriteAdjustmentY, sprite)
      .setScale(0.85, 0.85)
      .setFlipX(left);
    const mask = icon.createGeometryMask();

    player.setMask(mask);
  }

  private createPlayerLabel(
    x: number,
    y: number,
    name: string,
    left: boolean
  ): void {
    x = left ? x : Utils.getDimensions(this).width - x;
    this.add
      .text(x, y, name, {
        fontFamily: "Special Elite",
        fontSize: "18px",
        color: "black",
      })
      .setOrigin(left ? 0 : 1, 0);
  }

  private createPlatform(
    getX: (platformWidth: number) => number,
    getY: (platformHeight: number) => number,
    scale: number,
    texture: string
  ): Phaser.GameObjects.Sprite {
    const platforms: Phaser.Physics.Arcade.Group = this._platforms!;
    const platform: Phaser.GameObjects.Sprite = platforms.create(0, 0, texture);
    platform.setScale(scale, scale);
    const platformWidth: number = Utils.getScaledDimensions(platform).width;
    const platformHeight: number = Utils.getScaledDimensions(platform).height;
    platform.setPosition(getX(platformWidth), getY(platformHeight));
    Utils.getBody(platform).setImmovable(true);
    return platform;
  }

  // PRELOAD event - overridden
  public preload(): void {
    const assetsFolderPath: string = "assets/";
    const spritesFolderPath: string = "sprites/";
    this.load.image(
      "background",
      `${assetsFolderPath}backgrounds/bg_classic.png`
    );
    this.load.image(
      "ground",
      `${assetsFolderPath}${spritesFolderPath}ground_platform_classic.png`
    );
    this.load.image(
      "ground_med",
      `${assetsFolderPath}${spritesFolderPath}ground_platform_med_classic.png`
    );
    this.load.image(
      "spit_left",
      `${assetsFolderPath}${spritesFolderPath}spit_left.png`
    );
    this.load.image(
      "spit_right",
      `${assetsFolderPath}${spritesFolderPath}spit_right.png`
    );
    this.loadPlayerSpriteSheets();
  }

  private loadPlayerSpriteSheets(): void {
    const path = "assets/spritesheets/";
    const spriteSheetConfig = {
      frameWidth: 141,
      frameHeight: 120,
      startFrame: 0,
      endFrame: 8,
      margin: 5,
      spacing: 10,
    };
    playerConfigs.forEach((player) => {
      this.load.spritesheet(
        player.sprite,
        `${path}${player.sprite}_ss.png`,
        spriteSheetConfig
      );
    });
  }

  // CREATE event - overridden
  public create(): void {
    const { width: sceneWidth, height: sceneHeight } = Utils.getDimensions(
      this
    );

    const background: Phaser.GameObjects.Image = this.add.image(
      sceneWidth / 2,
      sceneHeight / 2,
      "background"
    );
    background.setScale(0.8, 0.8);
    background.setDepth(backgroundDepth);

    this.createPlayerHUD();

    this._platforms = this.physics.add.group();

    this._ground = this.createPlatform(
      (_) => sceneWidth / 2,
      (platformHeight: number) => sceneHeight - platformHeight / 2,
      1,
      "ground"
    );
    this.createPlatform(
      (platformWidth: number) => sceneWidth - platformWidth / 2,
      (_) => sceneHeight - 219.4,
      0.39,
      "ground"
    );
    this._topLeftPlatform = this.createPlatform(
      (platformWidth: number) => platformWidth / 2,
      (_) => sceneHeight - 529.4,
      0.39,
      "ground"
    );
    this._movingPlatform = this.createPlatform(
      (_) => sceneWidth / 3,
      (_) => sceneHeight - 374.4,
      0.39,
      "ground_med"
    );
    Utils.getBody(this._movingPlatform).setFrictionX(1);

    const startMovingPlatformRight: boolean = Phaser.Math.Between(0, 1) == 1;
    this._movingPlatformVelocity = startMovingPlatformRight ? 100 : -100;

    const getStartingYPosition = (platform: Phaser.GameObjects.Sprite) =>
      platform.y - Utils.getScaledDimensions(platform).height / 2;

    const playerOne: Player = new Player(
      this,
      getStartingYPosition(this._topLeftPlatform),
      playerConfig.playerOne
    );
    const playerTwo: Player = new Player(
      this,
      getStartingYPosition(this._ground),
      playerConfig.playerTwo
    );

    this._players = [playerOne, playerTwo];

    playerOne.registerEnemyPlayer(playerTwo);
    playerTwo.registerEnemyPlayer(playerOne);

    this.cameras.main.fadeIn(1500);
  }

  // UPDATE event - overridden
  public update(): void {
    const { width: sceneWidth } = Utils.getDimensions(this);
    const movingPlatform: Phaser.GameObjects.Sprite = this._movingPlatform!;
    const { width: movingPlatformWidth } = Utils.getScaledDimensions(
      movingPlatform
    );
    const { _players: players, _platforms: platforms } = this;

    for (let i = 0; i < players.length; i++) {
      this.physics.collide(players[i], platforms);
      for (let j = i + 1; j < players.length; j++) {
        this.physics.collide(players[i], players[j]);
      }
    }

    if (movingPlatform.x <= movingPlatformWidth / 2) {
      this._movingPlatformVelocity = 80;
    }
    if (movingPlatform.x >= sceneWidth - movingPlatformWidth / 2) {
      this._movingPlatformVelocity = -80;
    }
    Utils.getBody(movingPlatform).setVelocityX(this._movingPlatformVelocity);

    players.forEach((player) => {
      player.update();
    });

    const alivePlayers: Player[] = players.filter((player) => player.isAlive());
    if (alivePlayers.length < players.length) {
      const params: GameOverSceneParams = new GameOverSceneParams(
        alivePlayers.map((player) => player.getName())
      );
      this.scene.start("gameOver", params);
    }
  }
}

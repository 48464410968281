import * as Phaser from "phaser";

import { ArenaScene } from "./arenaScene";
import { StartScene } from "./startScene";
import { GameOverScene } from "./gameOverScene";

window.onload = () => {
  const gameConfig: Phaser.Types.Core.GameConfig = {
    type: Phaser.AUTO,
    scale: {
      mode: Phaser.Scale.FIT,
      autoCenter: Phaser.Scale.CENTER_BOTH,
    },
    parent: "canvas-container",
    width: 1024,
    height: 768,
    physics: {
      default: "arcade",
      arcade: {
        fixedStep: true,
        overlapBias: 8,
      },
    },
    scene: [StartScene, ArenaScene, GameOverScene],
  };
  const game: Phaser.Game = new Phaser.Game(gameConfig);

  window.addEventListener(
    "resize",
    () => {
      // Without this 'hit areas' aren't updated when the window is resized
      // (eg the area where a button can be clicked is no longer aligned with the button itself)
      game.scale.refresh();
    },
    false
  );
};
